import { closings } from "./closings";
import { extract } from "./extract";
import { debtorsReport } from "./debtors-report";

export const multivendor = {
	closings,
	extract,
	debtorsReport,
	productsSold: {
		title: "Products sold by vendor",
		activeTab: "product-sold-by-vendor",
		bonus: "Bonus per product",
	},
	revenues: {
		title: "Vendor Billing",
		description: "Multivendor reports of each vendor",
		period: "values ​​referring to the period {{ since }} - {{ until }}",
		activeTab: "vendors-revenue",
		columns: {
			name: "Vendor",
			totalSoldValue: "Products sold",
			totalTipValue: "Service",
			subtotal: "Subtotal",
			rent: "Rent",
			revenue: "Revenue",
			billing: "Billing",
			percentageFee: "Fee",
			salesFee: "Sales Fee",
			salesFeeValue: "Sales Transfer",
			serviceFee: "Service Fee",
			serviceFeeValue: "Service Transfer",
			percentageFeeText: "Fee in {{fee}}",
			adjustValue: "Adjustments",
			electrical: "Electrical",
			hydraulics: "Hydraulics",
			supply: "Supply",
			cleaning: "Cleaning",
			other: "Other",
			cashRetention: "Cash retention",
			cashPayment: "Cash Payments",
			voucherPayment: "Voucher Payments",
			creditPayment: "Credit Payments",
			debitPayment: "Debit Payments",
			total: "Total",
			totalSoldValueInCredit: "Credit Invoicing",
			totalSoldValueInDebit: "Debit Invoice",
			totalSoldValueInPix: "Invoicing in Pix",
			creditFee: "Credit Fee",
			debitFee: "Debit Fee",
			pixFee: "Fee on Pix",
			totalSoldValueInCreditFeeValue: "Credit Charge",
			totalSoldValueInDebitFeeValue: "Debit Charge",
			totalSoldValueInPixFeeValue: "Charge on Pix",
			totalSoldValueInOtherPaymentMethods: "Billing in other payment methods",
			billingTooltip:
				"This column refers to the sum of amounts billed by the vendor for products, services, adjustments and recharge leftovers.",
			revenueTooltip:
				"This column is the result of billing minus all fees and adjustments charged to the vendor.",
			voucherRetention: "Voucher retention",
			productsFee: "Transfer of Products with Special Rate",
			rechargeLeftovers: "Recharge leftovers",
			floorComplement: "Guaranteed minimum floor complement",
		},
		sheet: {
			title: "Vendor Billing",
			filename: "vendor_billing_in_local",
			columns: {
				name: "Vendor",
				totalSoldValue: "Products sold",
				totalTipValue: "Service",
				subtotal: "Subtotal",
				rent: "Rent",
				revenue: "Revenue",
				billing: "Billing",
				percentageFee: "Fee",
				salesFee: "Sales Fee",
				salesFeeValue: "Sales Transfer",
				serviceFee: "Service Fee",
				serviceFeeValue: "Service Transfer",
				percentageFeeText: "Fee in {{fee}}",
				adjustValue: "Adjustments",
				electrical: "Electrical",
				hydraulics: "Hydraulics",
				supply: "Supply",
				cleaning: "Cleaning",
				other: "Other",
				cashRetention: "Cash retention",
				totalSoldValueInCredit: "Credit Invoicing",
				totalSoldValueInDebit: "Debit Invoice",
				totalSoldValueInPix: "Invoicing in Pix",
				creditFee: "Credit Fee",
				debitFee: "Debit Fee",
				pixFee: "Fee on Pix",
				totalSoldValueInCreditFeeValue: "Credit Charge",
				totalSoldValueInDebitFeeValue: "Debit Charge",
				totalSoldValueInPixFeeValue: "Charge on Pix",
				totalSoldValueInOtherPaymentMethods: "Billing in other payment methods",
				voucherRetention: "Voucher retention",
				productsFee: "Transfer of Products with Special Rate",
				rechargeLeftovers: "Recharge leftovers",
				floorComplement: "Guaranteed minimum floor complement",
			},
		},
		totalizers: {
			totalSold: "Total vendor revenue",
			totalReceipt: "Total revenue with vendors",
			highestVendor: "Highest Vendor",
		},
		emptyState: {
			title: "No information found",
			description: "We couldn't find any data in the selected period.",
			subDescription: "Check if the period is valid or try a new date.",
		},
	},
};
